<template>
  <div class="absolute inset-0 overflow-y-auto">
    <div class="container mx-auto overflow-y-scroll no-scrollbar">
      <div
        class="w-full flex flex-row items-center justify-center relative gap-6 p-6 lg:gap-12 lg:p-12"
      >
        <div class="w-full flex flex-col gap-6 lg:w-[40%]">
          <div class="flex flex-col items-center">
            <Logo variant="full" />
          </div>
          <JoinForm />
        </div>
      </div>
    </div>
    <UNotifications />
  </div>
</template>

<script setup>
  definePageMeta({
    skipAuth: true,
  })

  useHead({
    title: "Sign Up - favoritely.com",
    meta: [{ name: "description", content: "Sign Up - favoritely.com" }],
  })
</script>
