import { useUserStore } from "@/store/user"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()
  const toast = useToast()

  if (userStore?.arrested && to.path !== "/account/holding") {
    return navigateTo("/account/holding")
  }

  if (!userStore?.arrested && to.path === "/account/holding") {
    return navigateTo("/")
  }

  if (userStore.loggedIn) {
    if (!userStore.emailConfirmed && !to.meta.skipEmailConfirmation) {
      toast.add({ title: "Please confirm your email" })
      return navigateTo("/settings")
    }
  } else {
    if (to.meta.skipAuth) return

    if (!["/join", "/login"].includes(to.path)) {
      return navigateTo("/join")
    }
  }
})
